import React, { Component } from "react";
import { Layout } from "../../../components/Layout";
import Map from "../../../components/Map";
import SEO from "../../../components/SEO";

export default class PoliticalLeaning extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="County Political Donations Leaning"
          description="Amount of money poured into each County per Political Party"
        />
        <Map />
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 20,
            zIndex: 999,
            margin: 20,
            width: "60%",
            border: "#fff",
            backgroundColor: "#121212",
            borderRadius: 3,
            fontSize: "1.3em"
          }}
        >
          <span>
            County Political Donations Leaning Data. Taller bars means larger
            donations leaning towards either political party. Code is available
            at
            <a href="https://gitlab.com/igorlp/py-county-political-leaning/-/raw/master/main.py">
              <span> </span>
              <u>GitLab</u>
            </a>
          </span>
        </div>
      </Layout>
    );
  }
}
