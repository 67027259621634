import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import geoJSONData from "../pages/projects/political-leaning/georesultsdata_simplified.json";

const isBrowser = typeof window !== "undefined";

const mapContainerStyle = {
  height: isBrowser ? window.innerHeight + "px" : "100%",
  width: "100vw"
};

const Map = () => {
  const mapContainerRef = useRef(null);

  const [map, setMap] = useState(null);

  useEffect(() => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    });

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: {
        version: 8,
        sources: {},
        layers: [
          {
            id: "background",
            type: "background",
            paint: { "background-color": "rgba(255,255,255,0)" },
            minzoom: 0,
            maxzoom: 22
          }
        ]
      },
      center: [-95.1937, 35.7743],
      pitch: 10,
      zoom: 4
    });

    map.addControl(new mapboxgl.NavigationControl());

    map.on("load", function() {
      map.addSource("zips", {
        type: "geojson",
        data: geoJSONData,
        buffer: 0
      });

      map.addLayer({
        id: "zip",
        type: "fill-extrusion",
        source: "zips",
        paint: {
          "fill-extrusion-color": [
            "interpolate",
            ["linear"],
            ["get", "DIFFERENCE"],
            -100000.0,
            "#0302FC",
            -40000.0,
            "#2A00D5",
            -10000,
            "#63009E",
            10000,
            "#A1015D",
            40000.0,
            "#D80027",
            100000.0,
            "#FE0002"
          ],
          "fill-extrusion-height": ["/", ["abs", ["get", "DIFFERENCE"]], 5],
          "fill-extrusion-opacity": 0.9
        },
        minzoom: 0,
        maxzoom: 22
      });

      var popup = new mapboxgl.Popup({
        offset: 10,
        closeButton: false,
        closeOnClick: false
      });

      map.on("mousemove", "zip", function(e) {
        map.getCanvas().style.cursor = "pointer";

        var coordinates = e.features[0].geometry.coordinates.slice();
        var description =
          "County Name: " +
          e.features[0].properties.NAME +
          "<br>County Code: " +
          e.features[0].properties.COUNTY_CODE +
          "<br>Leaning: " +
          (e.features[0].properties.LEANING === 1.0
            ? "Republican"
            : "Democratic") +
          "<br>Republican Donations: " +
          formatter.format(Math.abs(e.features[0].properties.REP)) +
          "<br>Democratic Donations: " +
          formatter.format(Math.abs(e.features[0].properties.DEM)) +
          "<br>Difference: " +
          formatter.format(Math.abs(e.features[0].properties.DIFFERENCE)) +
          "<br>Total Donations: " +
          formatter.format(
            e.features[0].properties.REP + e.features[0].properties.DEM
          );

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup
          .setLngLat(e.lngLat)
          .setHTML(description)
          .addTo(map);
      });

      map.on("mouseleave", "zip", function() {
        map.getCanvas().style.cursor = "";
        popup.remove();
      });
    });

    setMap(map);

    return () => map.remove();
  }, []);

  return <div id="map" ref={mapContainerRef} style={mapContainerStyle} />;
};

export default Map;
